import JoinRoom from './JoinRoom'
import Room from './Room';
import './styles.css'
import { useHMSStore, selectIsConnectedToRoom } from '@100mslive/react-sdk';

const MobileLive = () => {
    const isConnected = useHMSStore(selectIsConnectedToRoom)
    return (
        <div className='my-40 flex  justify-center items-center'>
            {isConnected
                ? <Room />
                : <JoinRoom />
            }
        </div>
    );
}

export default MobileLive;