import React from 'react';

const Live = () => {
  return (
    <div className='my-40 flex justify-center items-center'>
      <h1 className='text-2xl font-bold'>কাজ চলছে</h1>
    </div>
  );
}

export default Live;